import React from 'react'
import { Link } from 'gatsby'

function Footer() {
  return (
    <footer className="bg-gray-800 py-10 text-gray-200">
      <div className="container flex flex-col md:flex-row items-center">
        <nav
          aria-label="footer"
          className="mb-10 md:mb-0 md:order-2 md:ml-auto"
        >
          <ul className="flex flex-col md:flex-row items-center">
            {[
              {
                to: `/about`,
                label: 'About Us',
              },
              {
                to: `/pricing`,
                label: `Pricing`,
              },
              {
                to: `/contact`,
                label: `Contact Us`,
              },
              {
                to: `/privacy-policy`,
                label: `Privacy Policy`,
              },
            ].map(({ to, label }) => (
              <li key={to}>
                <Link className="py-3 md:py-0 md:px-4 inline-block" to={to}>
                  {label}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        <div>&copy; {new Date().getFullYear()} Real Estate Shows</div>
      </div>
    </footer>
  )
}

export default Footer
