import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'

import Header from './header'
import Footer from './footer'

function Layout({ children, flush, hideCta }) {
  return (
    <div className="flex flex-col min-h-screen">
      <Helmet
        bodyAttributes={{
          class: 'font-light antialiased font-sans text-gray-900',
        }}
      />
      <Header flush={flush} hideCta={hideCta} />

      <main className="flex-1 w-full">{children}</main>

      <Footer />
    </div>
  )
}

Layout.defaultProps = {
  flush: false,
  hideCta: false,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  flush: PropTypes.bool,
  hideCta: PropTypes.bool,
}

export default Layout
