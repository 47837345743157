import { Link } from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'

import Logo from '../images/logo.svg'
import { useAppState } from './state/PageWrapper'

function Header({ flush, hideCta }) {
  const { isNavOpen, setIsNavOpen, toggleNav } = useAppState()

  function getFlush() {
    if (true === flush) {
      return ``
    }

    return `mb-6 md:mb-16`
  }

  return (
    <header
      className={`bg-white sticky top-0 z-30 border-b border-gray-100 ${getFlush()}`}
    >

      <div className="container flex flex-wrap items-center justify-between p-4">
        <Link aria-label="home" to="/">
          <img alt="" className="w-48" src={Logo} />
        </Link>

        <button
          aria-label="toggle menu"
          className="lg:hidden"
          onClick={toggleNav}
        >
          <svg
            className="w-6 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isNavOpen ? `block` : `hidden`
          } bg-white z-20 pb-4 lg:pb-0 left-0 px-4 lg:px-0 shadow lg:shadow-none absolute lg:static lg:flex lg:items-center w-full lg:w-auto`}
          style={{ top: `100%` }}
        >
          {[
            {
              route: `/blog`,
              title: `Blog`,
            },
            {
              route: `/video-tours`,
              title: `Video Tours`,
            },
            {
              route: `/property-pages`,
              title: `Property Pages`,
            },
            {
              route: `/pricing`,
              title: `Pricing`,
            },
          ].map(link => (
            <Link
              activeClassName="font-semibold"
              className="block mt-4 lg:inline-block lg:mt-0 lg:ml-8 lg:py-3 text-center"
              key={link.title}
              onClick={() => setIsNavOpen(false)}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
          <a
            className="block mt-4 lg:mt-0 lg:inline-block lg:ml-8 text-center"
            href="https://app.realestateshows.com/login"
          >
            Sign In
          </a>
          {hideCta === false && (
            <Link
              className="btn btn-teal lg:ml-8 mt-4 lg:mt-0 px-4 py-3 text-sm block text-center lg:inline-block"
              onClick={() => setIsNavOpen(false)}
              to="/pricing"
            >
              Start Your Free Trial
            </Link>
          )}
        </nav>
      </div>
    </header>
  )
}

Header.defaultProps = {
  flush: false,
  hideCta: false,
}

Header.propTypes = {
  flush: PropTypes.bool,
  hideCta: PropTypes.bool,
}

export default Header
