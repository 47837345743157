import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'

// Internal dependencies
import { useSiteMetadata } from '../hooks'
function SEO({
  description,
  lang,
  title,
  slug,
  createdAt,
  updatedAt,
  meta,
  pageType,
}) {
  const siteMetadata = useSiteMetadata()
  const [cleanTitle, setCleanTitle] = useState(title)

  const metaDescription = description || siteMetadata.description
  const ogUrl = `${siteMetadata.siteUrl}${slug}`
  const defaultOpenGraphImage = `${siteMetadata.siteUrl}/images/res-default.png`

  // Desctructure our meta prop coming in
  const {
    title: seoMetaTitle,
    metaDescription: seoMetaDescription,
    openGraphTitle,
    openGraphDescription,
    openGraphImage,
    twitterDescription,
    twitterTitle,
    twitterImage,
  } = meta

  // Handle HTML symbols
  useEffect(() => {
    // Bail if there's nothing to do
    if (false === cleanTitle.includes(`&#`)) {
      return
    }

    let string = title
    string = string.replace(`&#8217;`, `'`)
    string = string.replace(`&#8230;`, `...`)

    setCleanTitle(string)
  }, [])

  const metaTags = [
    {
      property: `fb:app_id`,
      content: `149888691708771`,
    },
    {
      name: `description`,
      content: seoMetaDescription || metaDescription,
    },
    {
      property: `og:title`,
      content: openGraphTitle || cleanTitle,
    },
    {
      property: `og:description`,
      content: openGraphDescription || metaDescription,
    },
    {
      property: `og:type`,
      content: pageType,
    },
    {
      property: `og:image`,
      content:
        openGraphImage && openGraphImage.imageFile
          ? `${siteMetadata.siteUrl}${openGraphImage.imageFile.publicURL}`
          : defaultOpenGraphImage,
    },
    {
      property: `og:url`,
      content: ogUrl.endsWith(`/`) ? ogUrl : `${ogUrl}/`,
    },
    {
      property: `og:site_name`,
      content: siteMetadata.title,
    },
    {
      property: `og:locale`,
      content: `en_US`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: twitterTitle || openGraphTitle || cleanTitle,
    },
    {
      name: `twitter:description`,
      content: twitterDescription || openGraphDescription || metaDescription,
    },
    {
      // Google Search Console verificatin
      name: `google-site-verification`,
      content: `jsrJbF_ndhyIW2VRWRHt69iFL_HbwEUQwRQOwW-dItM`,
    },
  ]

  if (createdAt) {
    metaTags.push({
      name: `article:published_time`,
      content: createdAt,
    })
  }

  if (updatedAt) {
    metaTags.push({
      name: `article:updated_time`,
      content: updatedAt,
    })
  }

  if (twitterImage) {
    metaTags.push({
      name: `twitter:image`,
      content: `${siteMetadata.siteUrl}${twitterImage.imageFile.publicURL}`,
    })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      meta={metaTags}
      title={seoMetaTitle || cleanTitle}
      titleTemplate={`%s | ${siteMetadata.title}`}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  slug: ``,
  title: `Real Estate Shows`,
  pageType: `website`,
  meta: {},
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  meta: PropTypes.object,
  pageType: PropTypes.string,
}

export default SEO
